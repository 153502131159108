<template>
  <v-layout column>
    <v-layout align-center>
      <div class="template-label text-end">
        <dsb-label>
          {{ label }}:
        </dsb-label>

        <span
          v-if="isNotSingletonTemplate"
          class="warning--text"
        >
          <v-icon
            color="warning"
            x-small
          >
            warning
          </v-icon>
          This is not a singleton
        </span>

        <span v-if="!isWrongSidesUp">
          Sides up: {{ sidesUp }}
        </span>

        <span
          v-if="isWrongSidesUp"
          class="warning--text"
        >
          <v-icon
            color="warning"
            x-small
          >
            warning
          </v-icon>
          Sides up: {{ sidesUp }}
        </span>
      </div>

      <v-combobox
        v-model="templateName"
        :disabled="!hadAdminRole"
        :items="templatesOptions"
        :loading="templatesLoading"
        class="vbt-hide-form-control-details"
        placeholder="Start typing..."
        style="width: 245px;"
        item-text="name"
      />
    </v-layout>

    <v-layout
      v-if="isMultipage"
      class="mt-4"
    >
      <div class="rule-form-label" />

      <v-layout>
        <v-flex sm6>
          <v-checkbox
            :disabled="!hadAdminRole"
            :input-value="templateCollated"
            class="vbt-hide-form-control-details mt-4"
            label="Collated"
            @click="resetCollatedAndPseudo('collated', !templateCollated)"
          />
        </v-flex>

        <v-flex sm6>
          <v-checkbox
            :disabled="!hadAdminRole"
            :input-value="templatePseudo"
            class="vbt-hide-form-control-details mt-4"
            label="Pseudo"
            @click="resetCollatedAndPseudo('pseudo', !templatePseudo)"
          />
        </v-flex>
      </v-layout>
    </v-layout>

    <v-layout class="mt-4">
      <div class="rule-form-label text-end">
        <dsb-label :error="!templateMachineCategory ? 'Required' : ''">
          Printing Machine Category:
        </dsb-label>
      </div>

      <span
        v-if="machinesLoading"
        class="mt-2"
      >
        Loading...
      </span>

      <v-layout
        v-else
        column
      >
        <v-select
          v-model="templateMachineCategoryProp"
          :disabled="!hadAdminRole"
          :items="machineCategoriesOptions"
          :loading="templatesLoading"
          class="vbt-hide-form-control-details"
          style="width: 245px;"
          item-text="name"
          item-value="id"
        />
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { genGUID, wrapToLoadingFn } from '@helpers';

import { AuthNamespace, AppLocationsNamespace } from '@store/types';

import { DataListsApiService } from '@services/api';

export default {
  name: 'PressSheetTemplate',

  props: {
    label: {
      type: String,
      required: true,
    },

    templateId: {
      type: Number,
      required: true,
    },

    templateMachineCategory: {
      type: Number,
      required: true,
    },

    templateCollated: {
      type: Boolean,
      required: true,
    },

    templatePseudo: {
      type: Boolean,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },

    isMultipage: {
      type: Boolean,
      default: false,
    },

    templatesLoading: {
      type: Boolean,
      default: false,
    },

    oneItemOnSheet: {
      type: Boolean,
      required: true,
    },

    itemsCount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      machineCategoriesOptions: [],
      machinesLoading: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    templateName: {
      get() {
        return (this.templates.find(({ id }) => id === this.templateId) || {}).name || '';
      },
      set({ id }) {
        this.updateTemplateId(id);
      },
    },

    templateMachineCategoryProp: {
      get() {
        return this.templateMachineCategory;
      },
      set(id) {
        this.$emit('update:template-machine-category', id);
      },
    },

    templatesOptions() {
      return this.templates.map(({ id, name }) => ({ id, name }));
    },

    sidesUp() {
      return (this.templates.find(({ id }) => id === this.templateId) || {}).maxSidesUp || 1;
    },

    isNotSingletonTemplate() {
      return this.oneItemOnSheet ? this.sidesUp > 1 : false;
    },

    isWrongSidesUp() {
      return this.oneItemOnSheet
        ? false
        : ((this.sidesUp < 2) || Boolean(this.sidesUp % this.itemsCount));
    },
  },

  watch: {
    templatesOptions: {
      handler(templates) {
        if (!this.templateId && templates.length) {
          this.updateTemplateId(templates[0].id);
        }
      },
      immediate: true,
      deep: true,
    },

    templateId: {
      handler(id) {
        if (id) {
          this.getTemplatesMachineCategoriesByTemplateId(id);
        }
      },
      immediate: true,
    },
  },

  methods: {
    updateTemplateId(id) {
      this.$emit('update:template-id', id);
    },

    getTemplatesMachineCategoriesByTemplateId(id) {
      this.wrapToLoadingFn({
        req: DataListsApiService.getTemplatesMachineCategoriesByTemplateId.bind({}, {
          suffix: this.suffix,
          id,
        }),
        loadingFlagName: 'machinesLoading',
        onSuccess: (response) => {
          this.machineCategoriesOptions = response;
        },
      });
    },

    resetCollatedAndPseudo(type, value) {
      const isCollated = type === 'collated';

      this.$emit('update:template-collated', isCollated ? value : false);
      this.$emit('update:template-pseudo', isCollated ? false : value);
    },

    genGUID,
    wrapToLoadingFn,
  },
};
</script>

<style lang="css" scoped>
.template-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 190px;
  padding-right: 20px;
}
</style>
