import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import {
  genGUID,
  wrapToLoadingFn,
  Validator,
  ValidationRules,
} from '@helpers';

import {
  AuthNamespace,
  DataListsNamespace,
  DataListsActionTypes,
  EnumsNamespace,
  AppLocationsNamespace,
} from '@store/types';

import { createRule, createRulePressSheetTemplate } from '../../_models/rule';

import { RulesApiService } from '../../_services/rules.api.service';

import PressSheetTemplate from './_pressSheetTemplate.vue';

const dataLoadingOptions = {
  getterName: 'initOptions',
  searchable: false,
  paged: false,
};

const validator = Validator.createValidator({
  quantity: [
    ValidationRules.REQUIRED,
    ValidationRules.NUMBER,
    {
      rule: ValidationRules.MIN,
      value: 1,
    },
  ],
  itemsCount: [
    ValidationRules.REQUIRED,
    ValidationRules.NUMBER,
    {
      rule: ValidationRules.MIN,
      value: 2,
    },
    {
      rule: ValidationRules.MAX,
      value: 99,
    },
  ],
});

export default {
  name: 'DsbRuleForm',

  props: {
    rule: {
      type: Object,
      default: () => createRule({ withPressSheetTemplate: true }),
    },

    ruleName: {
      type: String,
      required: true,
    },

    isActiveRule: Boolean,
    creator: Boolean,
  },

  components: {
    PressSheetTemplate,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    Validator.useValidator(validator),
  ],

  data() {
    return {
      baseRule: '',
      form: createRule({ withPressSheetTemplate: true }),

      machinesTemplates: {},

      isRuleCheckLoading: false,
      productsListLoading: false,
      substratesListLoading: false,
      pressSheetsTemplatesListLoading: false,
      machinesTemplatesListLoading: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
    ...mapState(DataListsNamespace, [
      'productsList',
    ]),
    ...mapState(DataListsNamespace, {
      productsListOptions: ({ productsList: items }) => items.map(({ name }) => name),
      substratesListOptions: ({ substratesList: items }) => items.map(({ name }) => name),
      pressSheetsTemplatesList: ({ pressSheetsTemplatesList: items }) => items,
    }),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),
    ...mapGetters(EnumsNamespace, [
      'uvOrientations',
      'laminatings',
      'variableUvs',
      'covers',
      'foilings',
      'roundedCorners',
      'contracts',
    ]),

    signaturesTypesListOptions() {
      const { signatures } = this.productsList
        .find(({ name }) => name === this.form.productType) || {};

      return signatures || [];
    },

    isMultipage() {
      const { multipage = false } = this.productsList
        .find(({ name }) => name === this.form.productType) || {};

      return multipage;
    },

    isPoster() {
      return /poster/ig.test(this.form.productType);
    },

    ruleStr() {
      return JSON.stringify(this.rule);
    },

    isInvalidForm() {
      const { form } = this;

      return !form.productType
        || !form.orientation.length
        || form.pressSheetTemplates.some((t) => !t.id || !t.machineCategory);
    },

    hasChanges() {
      return this.baseRule !== JSON.stringify(this.form);
    },
  },

  watch: {
    ruleStr: {
      handler(newValue) {
        if (!this.creator) {
          Object.assign(this.form, JSON.parse(newValue));
          this.baseRule = JSON.stringify(this.form);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(DataListsNamespace, {
      initOptions(dispatch, { suffix }) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, DataListsActionTypes.GET_PRODUCTS_LIST, { suffix }),
          loadingFlagName: 'productsListLoading',
        });

        this.wrapToLoadingFn({
          req: dispatch.bind({}, DataListsActionTypes.GET_SUBSTRATES_LIST, { suffix }),
          loadingFlagName: 'substratesListLoading',
        });

        this.wrapToLoadingFn({
          req: dispatch.bind({}, DataListsActionTypes.GET_PRESS_SHEETS_TEMPLATES_LIST, { suffix }),
          loadingFlagName: 'pressSheetsTemplatesListLoading',
        });
      },
    }),

    addPressSheetTemplate() {
      this.form.pressSheetTemplates.push({
        ...createRulePressSheetTemplate(),
        // special key for optimized rerender
        _key: genGUID(), // eslint-disable-line
      });
    },

    addPressSheetTemplateForRemainings() {
      this.form.pressSheetTemplateForRemaining = createRulePressSheetTemplate();
    },

    removePressSheetTemplate(tmpl) {
      this.form.pressSheetTemplates.splice(this.form.pressSheetTemplates.indexOf(tmpl), 1);
    },

    getTwoOptionsColumns(options) {
      if (options.length < 3) {
        return options.map((opt) => [opt]);
      }

      const l = Math.ceil(options.length / 2);

      return [options.slice(0, l), options.slice(l)];
    },

    submitForm({ withTest = false } = {}) {
      const form = JSON.parse(JSON.stringify(this.form));

      form.name = this.ruleName;

      if (this.isMultipage) {
        form.oneItemOnSheet = false;
      } else {
        form.timeToTruck = form.oneItemOnSheet ? 0 : form.timeToTruck;

        form.pressSheetTemplates.forEach((psTmpl) => {
          psTmpl.collated = false;
          psTmpl.pseudo = false;
        });
      }

      form.pressSheetTemplates.forEach((psTmpl) => {
        delete psTmpl._key; // eslint-disable-line
      });

      if (!this.isPoster) {
        form.pressSheetTemplateForRemaining = null;
      }

      if (!this.creator) {
        delete form.id;
        delete form.status;
      }

      this.wrapToLoadingFn({
        req: RulesApiService.checkRule.bind({}, { suffix: this.suffix, rule: form }),
        loadingFlagName: 'isRuleCheckLoading',

        onSuccess: ({ success, messages }) => {
          if (!success) {
            this.$VBlackerTheme.notification.error(
              messages.join('; \n') || 'Sorry, rule is invalid!',
            );
          }
        },

        onFinally: () => {
          this.isRuleCheckLoading = false;

          if (!withTest) {
            this.$emit('submit', form);
          } else {
            this.$emit(this.hasChanges ? 'submit:and:test' : 'test', form);
          }
        },
      });
    },

    resetForm() {
      Object.assign(this.form, JSON.parse(this.ruleStr));
      this.$emit('reset:form');
    },

    genGUID,
    wrapToLoadingFn,
  },
};
